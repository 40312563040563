import * as React from 'react';

import Logo from '../imagens/logo.png';
import { Button, Grid, Typography } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Instagram } from '@mui/icons-material';

function Footer() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const openLink = (webUrl, appUrl) => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            window.open(appUrl, '_blank');
        } else {
            window.open(webUrl, '_blank', 'noopener,noreferrer');
        }
    };

    return (
        <div style={{ background: "#1C204A" }}>
            <Grid container spacing={2} style={{ padding: 32 }}>
                <Grid item xs={12} sm={12} md={3} style={{ paddingTop: 0, textAlign: isMobile ? "center" : "left" }}>
                    <img src={Logo} style={{ width: 200, marginLeft: "auto" }} />
                </Grid>
                <Grid item xs={12} sm={12} md={5} style={{ paddingTop: 0 }}>
                    <Typography style={{ color: "#fff", fontFamily: "roboto light", display: "flex", alignItems: "center", textAlign: isMobile ? "center" : "left", height: "100%" }}>
                        <label><b>Sobre nós</b><br /><br />
                            O <b>Esportize-se</b> é mais que uma plataforma, é o seu ponto de partida para viver o universo do esporte de forma prática e completa. Aqui, conectamos pessoas apaixonadas por esportes, oferecendo uma solução inovadora
                        </label>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} style={{ paddingTop: 0 }}>
                    {isMobile ? <div style={{ height: 40 }} /> : <></>}
                    <Typography style={{ color: "#fff", fontFamily: "roboto light", display: "flex", alignItems: "center", justifyContent: isMobile ? "center" : "right", textAlign: isMobile ? "center" : "right", height: "100%", width: "100%" }}>
                        <label>ESPORTIZE-SE
                            <br /><br />
                            @2024 Todos os Direitos Reservados
                            <br /><br /><Button color="inherit" onClick={() => openLink('https://www.instagram.com/esportizese.online', 'instagram://user?username=esportizese.online')} startIcon={<Instagram />} size="large" style={{ fontWeight: "bold", border: "solid 2px", background: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)" }}>Instagram</Button></label>
                    </Typography>
                </Grid>
            </Grid>
            {isMobile ? <div style={{ height: 40 }} /> : <></>}
        </div>
    );
}

export default Footer;
