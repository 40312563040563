import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingComponent from '../components/LoadingComponent';

import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme, formatarDataHora, stringAvatar } from '../functions/utils';
import { Avatar, Box, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Snackbar, Tab, Tabs, TextField, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';

// import Grid from '@mui/material/Grid2';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { CalendarMonthOutlined, CloudSyncOutlined, CloudUploadOutlined, DnsOutlined, FacebookOutlined, GroupsOutlined, InsightsOutlined, Instagram, KeyboardArrowDown, LinkedIn, LocationOnOutlined, Search, SentimentVerySatisfiedOutlined, SupportAgentOutlined, Visibility, WhatsApp, WidgetsOutlined } from '@mui/icons-material';
import Footer from '../components/Footer';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function RetiradasKit({ onChange, update, colorPrimary, colorSecondary, colorTertiary }, props) {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [loading, setLoading] = useState(false);
  const [retiradas, setRetiradas] = React.useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [isLogged, setIsLogged] = useState(false);

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };


  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const openLink = (webUrl, appUrl) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      window.open(appUrl, '_blank');
    } else {
      window.open(webUrl, '_blank', 'noopener,noreferrer');
    }
  };

  useEffect(() => {
    const logged = Cookies.get("isLogged");
    setIsLogged(logged)
    getRetiradas("RT")
  }, []);

  const getRetiradas = (tipo) => {
    axios.post(`https://esportizese.online/api/produtos.php`, {
      tipo: tipo,
    })
      .then(response => {
        response = response.data
        setRetiradas(response)

        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor (Código 1008)"));
  }

  const handleLogin = () => {
    onChange("", "perfil")
  }

  const handleOpenWhatsapp = (numero, corrida) => {
    const soNumero = numero.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "")
    window.open(`https://wa.me/55${soNumero}?text=Olá,tenho%20interesse%20na%20retirada%20do%20kit%20do%20evento%20*"${corrida}"*`, '_blank');
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>

      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleClose}
        message={msgSnack}
      />

      <LoadingComponent open={loading} />

      <div style={{ height: 80 }} />

      <div style={{ width: "100%", height: isMobile ? 150 : 240, backgroundImage: `url(https://esportizese.online/imagens/background.png)`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography style={{ textAlign: "center", fontSize: isMobile ? 50 : 100, fontWeight: "bold", color: "#FFF", fontStyle: "italic", textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7), -2px -2px 4px rgba(0, 0, 0, 0.7), 2px -2px 4px rgba(0, 0, 0, 0.7), -2px 2px 4px rgba(0, 0, 0, 0.7)" }}>
          Em Breve
        </Typography>
      </div>

      <div style={{ padding: 26 }}>

        {/* <div style={{ background: "white", display: "flex", alignItems: "center", height: 60, borderRadius: 8 }}>
          <div style={{ width: 16 }} />
          <Search style={{ fontSize: 28, color: "#888" }} />
          <div style={{ width: 16 }} />
          <TextField id="outlined-basic" variant="standard"
            InputProps={{
              style: { fontSize: 18 },
              disableUnderline: true,
            }} />
          <div style={{ width: 16 }} />
          <Button variant="text" style={{ textTransform: "none", marginLeft: "auto" }} color="inherit" startIcon={<LocationOnOutlined style={{ color: "#ff9900" }} />} endIcon={<KeyboardArrowDown />}>
            Local
          </Button>
          <div style={{ width: 16 }} />
          <Button variant="text" style={{ textTransform: "none" }} color="inherit" startIcon={<CalendarMonthOutlined style={{ color: "#ff9900" }} />} endIcon={<KeyboardArrowDown />}>
            Todas as datas
          </Button>
          <div style={{ width: 16 }} />
          <Button variant="text" style={{ textTransform: "none" }} color="inherit" startIcon={<WidgetsOutlined style={{ color: "#ff9900" }} />} endIcon={<KeyboardArrowDown />}>
            Modalidade
          </Button>
          <div style={{ width: 16 }} />
          <Button variant="contained" disableElevation style={{ textTransform: "none" }} >
            Buscar
          </Button>
          <div style={{ width: 16 }} />
        </div> */}

        <div style={{ height: 40 }} />

        {/* <Grid container spacing={0} style={{ width: '100%' }}>
          {retiradas && retiradas.map((retirada) => (
            <Grid item xs={12} sm={6} md={4} lg={3} style={{ paddingTop: 20, paddingRight: 10, paddingLeft: 10 }}>
              <Card sx={{ borderRadius: "8px" }}>
                <div style={{ padding: 16 }}>
                  <CardMedia
                    component="img"
                    height="170"
                    style={{ borderRadius: 8 }}
                    image={`https://esportizese.online/img-eventos/${retirada.ID_IT_PDT}.png`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                    }}
                  />
                  <CardContent style={{ textAlign: "left", padding: 0 }}>
                    <div style={{ height: 16 }} />
                    <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "bold", wordWrap: "break-word" }}>
                      {retirada.NM_IT}
                    </Typography>
                    <div style={{ height: 8 }} />
                    <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", fontSize: 14 }}>
                      <CalendarMonthOutlined style={{ fontSize: 20 }} />
                      <div style={{ width: 6 }} />
                      {formatarDataHora(retirada.DT_IT * 1000)}
                    </Typography>
                    <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", fontSize: 14 }}>
                      <LocationOnOutlined style={{ fontSize: 20 }} />
                      <div style={{ width: 6 }} />
                      {retirada.LC_IT}
                    </Typography>
                    <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", fontSize: 14 }}>
                      <WidgetsOutlined style={{ fontSize: 20 }} />
                      <div style={{ width: 6 }} />
                      {retirada.MD_IT}
                    </Typography>
                    <div style={{ height: 12 }} />
                    <Divider />
                    <div style={{ height: 12 }} />
                    <Typography style={{ color: "#585a75", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center" }}>
                      <Avatar {...stringAvatar(retirada.NM_AN)} style={{ width: 30, height: 30, fontSize: 12, fontWeight: "400" }} />
                      <div style={{ width: 8 }} />
                      {retirada.NM_AN}
                    </Typography>
                    <div style={{ height: 4 }} />
                      <Typography style={{ color: "#ABACBA", fontFamily: "roboto regular", fontWeight: "normal", display: "flex", alignItems: "center", wordWrap: "break-word", whiteSpace: "-moz-pre-wrap", whiteSpace: "pre-wrap" }}>
                        <IconButton style={{ width: 30, height: 30, background: "#2cd46b" }} onClick={() => handleOpenWhatsapp(retirada.TF_AN, retirada.NM_IT)} >
                          <WhatsApp style={{ color: "white", fontSize: 20 }} />
                        </IconButton>
                        <div style={{ width: 8 }} />
                        <label style={{ filter: isLogged ? "" : "blur(3px)", userSelect: "none", fontWeight: isLogged ? "" : "bold", color: isLogged ? "#ABACBA" : "#333" }}>{isLogged ? retirada.TF_AN : "(99) 99999-9999"}</label> 
                        {isLogged ? "" : <Tooltip title="Para visualizar o telefone, realize o login ou crie uma conta." onClick={handleLogin}>&nbsp;&nbsp;&nbsp;<Visibility style={{ color: "#1e3a8a", cursor: "pointer", marginBottom: -3 }} /> </Tooltip> }
                      </Typography>
                    <div style={{ height: 16 }} />
                    <Button variant="contained" color="primary" disabled={retirada.ST_IT === "F"} fullWidth size="large" style={{ textTransform: 'none', fontFamily: "roboto light", fontWeight: "bold" }}>
                      {retirada.ST_IT === "F" ? "Encerrado" : "Solicitar retirada"}
                    </Button>
                  </CardContent>
                </div>
              </Card>
            </Grid>
          ))}
        </Grid> */}

      </div>

      <div style={{ height: 100 }} />

      <Footer />

    </ThemeProvider >
  );
}

export default RetiradasKit;