import { Alert, AppBar, Avatar, Box, Button, Divider, Fade, FormControl, IconButton, InputAdornment, ListItemButton, ListItemIcon, Menu, MenuItem, Modal, Select, TextField, Toolbar, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect, useRef } from 'react';
import { AccountCircleOutlined, ExpandMoreOutlined, LoginOutlined, MenuOutlined, PhoneOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Logo from '../imagens/logo.png';
import { stringAvatar } from '../functions/utils';
import Cookies from 'js-cookie';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `none`,
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
    paddingLeft: 0,
    marginTop: -4,
    borderRadius: 6,
    backgroundColor: "#f8f8f8",
}));

const SideMenu = ({ onChange, update, colorPrimary }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [currentPage, setCurrentPage] = useState("repasses");
    const [open, setOpen] = React.useState(false);
    const [isLogged, setIsLogged] = React.useState(false);
    const [nomeUsuario, setNomeUsuario] = React.useState("");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPerfil = Boolean(anchorEl);
    const openSolucoes = Boolean(anchorEl);

    const handleOpenPerfil = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePerfil = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const changePage = (page) => {
        onChange("", page);
        setCurrentPage(page);
        setOpen(false)
        handleClosePerfil();
    }

    useEffect(() => {
        const isLogged = Cookies.get('isLogged');
        setIsLogged(isLogged);
        if (isLogged) {
            setNomeUsuario(Cookies.get("nmUsr"))
        }
    }, []);

    useEffect(() => {
        if (update && update.includes("LG___")) {
            setIsLogged(true)
            setNomeUsuario(Cookies.get("nmUsr"))
        }
        if (update && update.includes("UPDT___")) {
            const page = update.replaceAll("UPDT___", "")
            changePage(page)
        }
    }, [update]);

    const handleLogout = () => {
        onChange("logout", "");
        Cookies.remove('isLogged');
        Cookies.remove('emUsr');
        Cookies.remove('cpUsr');
        Cookies.remove('cUsr');
        Cookies.remove('iUsr');
        Cookies.remove('nmUsr');
        Cookies.remove('tpCt');
        setIsLogged(false);
        setOpen(false);
        handleClosePerfil();
    }

    return (
        <>
            <AppBar position="fixed" style={{ backgroundColor: `#1E3A8A`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto", height: 85, display: "flex", justifyContent: "center" }}>
                <Toolbar>

                    {isMobile ?
                        <>
                            <IconButton size="large" color="inherit" style={{ color: "#2B3AE7" }} onClick={() => setOpen(true)}>
                                <MenuOutlined style={{ fontSize: 42 }} />
                            </IconButton>
                            <img src={Logo} style={{ width: 90, marginLeft: "auto" }} />
                        </>
                        :
                        <>
                            <img src={Logo} style={{ width: 90, margin: 20 }} />
                            <Button variant="text" style={{ textTransform: 'none', fontWeight: "bold", fontSize: 16, fontFamily: "roboto light", marginLeft: "auto", color: currentPage === "loja" ? "#fff" : "#fff", borderBottom: currentPage === "loja" ? "solid 2px #ff9900" : "", borderRadius: 0 }} onClick={() => changePage("loja")}>
                                Loja
                            </Button>
                            <Button variant="text" style={{ textTransform: 'none', fontWeight: "bold", fontSize: 16, fontFamily: "roboto light", color: currentPage === "retiradas" ? "#fff" : "#fff", borderBottom: currentPage === "retiradas" ? "solid 2px #ff9900" : "", borderRadius: 0 }} onClick={() => changePage("retiradas")}>
                                Retiradas
                            </Button>
                            <Button variant="text" style={{ textTransform: 'none', fontWeight: "bold", fontSize: 16, fontFamily: "roboto light", color: currentPage === "repasses" ? "#fff" : "#fff", borderBottom: currentPage === "repasses" ? "solid 2px #ff9900" : "", borderRadius: 0 }} onClick={() => changePage("repasses")}>
                                Repasses
                            </Button>
                            {/* <Button variant="text" style={{ textTransform: 'none', fontWeight: "bold", fontSize: 16, fontFamily: "roboto light", marginLeft: 10, color: currentPage === "contato" ? "#fff" : "#fff", borderBottom: currentPage === "contato" ? "solid 2px #ff9900" : "", borderRadius: 0 }} onClick={() => changePage("contato")}>
                                Contato
                            </Button> */}
                            <div style={{ width: 20 }} />
                            <div style={{ height: 30, background: "#fff", width: 2 }} />
                            <div style={{ width: 20 }} />
                            {isLogged && nomeUsuario ?
                                <>
                                    <Avatar {...stringAvatar(nomeUsuario)} style={{ width: "38px", height: "38px", fontSize: 14, fontWeight: "400", marginLeft: 16, cursor: "pointer", border: currentPage === "perfil" ? "solid 2px #ff9900" : "solid 2px #fff" }} onClick={handleOpenPerfil} />
                                    <Menu id="perfil" anchorEl={anchorEl} open={openPerfil} onClose={handleClosePerfil}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <div style={{ height: 10 }} />
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Avatar {...stringAvatar(nomeUsuario)} style={{ width: "38px", height: "38px", fontSize: 14, fontWeight: "400", marginLeft: 16 }} />
                                            <div style={{ width: 10 }} />
                                            <Typography style={{ fontSize: 14, color: "#999" }}>
                                                <label style={{ fontSize: 16, color: "#1B1B1B" }}>{nomeUsuario}</label><br />
                                                {Cookies.get("emEst")}
                                            </Typography>
                                        </div>

                                        <div style={{ height: 10 }} />
                                        <Divider />
                                        <List style={{ width: 315, fontSize: 16 }}>
                                            <ListItem button onClick={() => changePage("perfil")} style={{ cursor: "pointer" }}>
                                                <AccountCircleOutlined style={{ marginRight: 10, marginLeft: 4, color: "#464545" }} />
                                                <ListItemText primary="Ver perfil" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                            </ListItem>
                                            <ListItem button onClick={handleLogout} style={{ cursor: "pointer" }}>
                                                <LoginOutlined style={{ marginRight: 14, color: "#464545" }} />
                                                <ListItemText primary="Sair" primaryTypographyProps={{ color: "#464545", fontWeight: "bold" }} />
                                            </ListItem>
                                        </List>
                                    </Menu>
                                </>
                                :
                                <Avatar style={{ width: "38px", height: "38px", fontSize: 14, fontWeight: "400", marginLeft: 16, cursor: "pointer", border: currentPage === "perfil" ? "solid 2px #ff9900" : "solid 2px #fff" }} onClick={() => changePage("perfil")} />
                            }

                        </>
                    }
                </Toolbar>
            </AppBar>

            {isMobile &&
                <Drawer open={open} onClose={toggleDrawer(false)}>
                    <div style={{ display: "flex", alignItems: "center", color: "#999", fontSize: 12, fontWeight: "400" }}>
                        <img src={Logo} style={{ width: 100, marginLeft: 20 }} />
                    </div>
                    <List style={{ width: 315, padding: 20 }}>
                        <ListItem button style={{ background: currentPage === "loja" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("loja")}>
                            <ListItemText primary="Loja" primaryTypographyProps={{ color: currentPage === "loja" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "retiradas" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("retiradas")}>
                            <ListItemText primary="Retiradas" primaryTypographyProps={{ color: currentPage === "retiradas" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        <ListItem button style={{ background: currentPage === "repasses" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("repasses")}>
                            <ListItemText primary="Repasses" primaryTypographyProps={{ color: currentPage === "repasses" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        {/* <ListItem button style={{ background: currentPage === "contato" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("contato")}>
                            <ListItemText primary="Contato" primaryTypographyProps={{ color: currentPage === "contato" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem> */}
                        <ListItem button style={{ background: currentPage === "perfil" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={() => changePage("perfil")}>
                            <ListItemText primary={isLogged ? "Ver perfil" : "Login"} primaryTypographyProps={{ color: currentPage === "perfil" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                        </ListItem>
                        {isLogged &&
                            <ListItem button style={{ background: currentPage === "contato" ? "#F2F2F2" : "", borderRadius: 6 }} onClick={handleLogout}>
                                <ListItemText primary="Sair" primaryTypographyProps={{ color: currentPage === "contato" ? "#2B3AE7" : "#464545", fontWeight: "400" }} />
                            </ListItem>
                        }
                    </List>
                </Drawer>
            }


        </>
    );
};

export default SideMenu;
